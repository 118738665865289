<template>
  <main>
    <div class="section is-medium">
      <div class="container">
        <div class="columns is-centered">
          <div
            class="column is-12-mobile is-9-tablet is-6-desktop is-5-widescreen"
          >
            <h1 class="title is-4">{{ $t('signup_title') }}</h1>
            <!-- <h2 class="subtitle">{{$t('signup_subtitle')}}</h2> -->
            <form @submit="signUpAndGo()" onsubmit="return false;" disabled>
              <error-notifications :errors="errors" />
              <div class="field">
                <sqr-input-email
                  label="signup_email"
                  name="email"
                  :value="email"
                  @change="fieldSet({ field: 'email', value: $event })"
                  size="medium"
                  :valid="!$v.email.$invalid"
                  :invalid="$v.email.$error"
                  @keyup.enter="signUpAndGo()"
                />
                <div
                  class="help is-danger"
                  v-if="$v.email.$error && !$v.email.required"
                >
                  {{ $t('validation_required') }}
                </div>
                <div
                  class="help is-danger"
                  v-else-if="$v.email.$error && !$v.email.email"
                >
                  {{ $t('validation_email') }}
                </div>
              </div>
              <div class="field">
                <sqr-input-password
                  label="signup_password"
                  name="password"
                  autocomplete="password"
                  v-model="password"
                  size="medium"
                  :valid="!$v.password.$invalid"
                  :invalid="$v.password.$error"
                  @keyup.enter="signUpAndGo()"
                />
                <div class="help is-danger" v-if="$v.password.$error">
                  {{ $t('validation_required') }}
                </div>
              </div>
              <div class="field">
                <div class="is-pulled-right">
                  <sqr-router-link
                    label="signup_lost"
                    to-name="password-lost"
                    is-link
                  />
                </div>
                <div>
                  <sqr-router-link
                    label="signup_sign_in"
                    to-name="sign-in"
                    is-link
                  />
                </div>
              </div>
              <div class="field">
                <label class="label">&nbsp;</label>
                <div class="control">
                  <sqr-button
                    icon="sign-in"
                    label="signup_sign_up"
                    color="primary"
                    @click="signUpAndGo()"
                    size="large"
                    is-fullwidth
                    :is-loading="loading"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import { validationMixin } from 'vuelidate';
import { email, required } from 'vuelidate/lib/validators';

import SqrButton from '@/sqrd/SqrButton';
import SqrInputEmail from '@/sqrd/SqrInputEmail';
import SqrInputPassword from '@/sqrd/SqrInputPassword';
import SqrRouterLink from '@/sqrd/SqrRouterLink';

import ErrorNotifications from '@/components/ErrorNotifications';

export default {
  name: 'SignIn',
  mixins: [validationMixin],
  components: {
    SqrButton,
    SqrInputEmail,
    SqrInputPassword,
    SqrRouterLink,
    ErrorNotifications,
  },
  computed: {
    ...mapGetters('auth', ['email', 'loading', 'errors']),
  },
  data() {
    return {
      password: '',
    };
  },
  validations: {
    email: { email, required },
    password: { required },
  },
  mounted() {
    this.reset({});
  },
  methods: {
    ...mapMutations('auth', ['fieldSet']),
    ...mapActions('auth', ['createUserWithEmailAndPassword', 'reset']),

    async signUpAndGo() {
      try {
        if (this.$v.$invalid) {
          this.$v.$touch();
          return;
        }
        const email = this.email;
        const password = this.password;
        await this.createUserWithEmailAndPassword({ email, password });
        this.$router.push({ name: 'start-user' });
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
</script>
